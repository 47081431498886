import React, { useEffect, useState } from "react";
import TitleListImgSection from "../TitleListImgSection";
import WeBuildYourTeamImg from "../../assets/Images/PodModel/steps/we-assemble-your-team.svg";
import WeHandleYourOpsImg from "../../assets/Images/PodModel/steps/we-handle-your-operation.svg";
import YouGrowWithoutLimitsImg from "../../assets/Images/PodModel/steps/you-grow-without-limits.svg";
import Line2 from "../../assets/Images/PodModel/steps/line1.svg";
import Line1 from "../../assets/Images/PodModel/steps/line2.svg";
import CardDetailsWithIcon from "../CardDetailsWithIcon";
import DOEImg from "../../assets/Images/PodModel/leaders/doe.svg";
import CTOImg from "../../assets/Images/PodModel/leaders/cto.svg";
import VPEImg from "../../assets/Images/PodModel/leaders/vpe.svg";
import HSDImg from "../../assets/Images/PodModel/leaders/hsd.svg";
import PMImg from "../../assets/Images/PodModel/leaders/pm.svg";
import LetsTalkCTA from "../LetsTalkCTA";
import DataService from "../../services/api/data_services";
import RightArrow from "../../assets/Icons/go.svg";
import CTAImg from "../../assets/Images/PodModel/cta/cta-growth.png";

import Button from "../Button";
import Miniview from "../../templates/MiniView";
import CtaCardSection from "../CTASection/ctaCard2";
import RevealAnimation from "../RevealAnimation";
import StepsHoverCard from "../StepsHoverCard";

function Overview({ btnClick }) {
  const [clientStoryData, setClientStoryData] = useState([]);
  const ListStep1 = {
    title: "We build your team",
    items: [
      {
        title: "Top-tier talent:",
        description:
          "Digiryte filters India’s talent pool to guarantee you work with elite developers. Our recruitment strategy includes engaging developers from renowned industry leaders like Google and Amazon.",
      },
      {
        title: "Rigorous selection:",
        description:
          " With 1.5 million engineers graduating annually in India. We ensure only the top 3% join our ranks after rigorous screening.",
      },
    ],
  };
  const ListStep2 = {
    title: "We handle your operation",
    items: [
      {
        title: "Administrative support:",
        description:
          "Digiryte handles your heavy lifting. Allowing you to focus on core business objectives and maximise ROI.",
      },
      {
        title: "Streamlined operations:",
        description:
          "Our hands-on approach ensures that your administrative burdens are managed efficiently, giving you the freedom to focus on strategic initiatives.",
      },
    ],
  };
  const ListStep3 = {
    title: "You grow without limits",
    items: [
      {
        title: "Capacity expansion:",
        description:
          "Digiryte supports our partners to allow you to increase resource capacity and rapidly scale your operations.",
      },
      {
        title: "Cultural alignment:",
        description:
          "Our dedicated Organisational Psychologists ensure that your vision and culture are seamlessly implemented across your India team, fostering a cohesive work environment.",
      },
    ],
  };
  const tableData = [
    {
      name: "Recruiting & Hiring Time",
      hireLocally: "1 - 3 Months",
      contractors: "1 - 12 Weeks",
      digiRyteModel: "1 - 14 Days",
    },
    {
      name: "Cost In Acquisition",
      hireLocally: "£ 8000 - £30000",
      contractors: "£ 8000 & Less",
      digiRyteModel: "0",
    },
    {
      name: "Cost In Training",
      hireLocally: "£ 4000 - £12000",
      contractors: "0",
      digiRyteModel: "0",
    },
    {
      name: "Project Failure Risk",
      hireLocally: "Low",
      contractors: "High",
      digiRyteModel: "Extremely Low",
    },
    {
      name: "Project Quality",
      hireLocally: "£ 8000 - £30000",
      contractors: "£ 8000 & Less",
      digiRyteModel: "0",
    },
    {
      name: "Cost In Acquisition",
      hireLocally: "Better",
      contractors: "Good",
      digiRyteModel: "Best",
    },
    {
      name: "Professional Liability",
      hireLocally: "You Own",
      contractors: "You Own",
      digiRyteModel: "Digiryte Own",
    },
  ];

  useEffect(() => {
    DataService.getData(
      `/admin/api/post?limit=3&page=1&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="common-bg-light headline">
        <RevealAnimation component={"h2"} className="">
          Explore how we scale your software development operations in India!
        </RevealAnimation>
      </div>
      <TitleListImgSection
        subTitle={ListStep1.title}
        listItems={ListStep1.items}
        imageUrl={WeBuildYourTeamImg}
        wrapClassName="mt-70"
        stepCount="01"
        btnClick={() => btnClick("we-build-your-team")}
      />
      <RevealAnimation animation="zoom-in" className="divide-line">
        <img src={Line1} alt="" />
      </RevealAnimation>
      <TitleListImgSection
        subTitle={ListStep2.title}
        listItems={ListStep2.items}
        imageUrl={WeHandleYourOpsImg}
        wrapClassName="row-reverse"
        stepCount="02"
        btnClick={() => btnClick("we-handle-your-operation")}
      />
      <RevealAnimation animation="zoom-in" className="divide-line">
        <img src={Line2} alt="" />
      </RevealAnimation>
      <TitleListImgSection
        subTitle={ListStep3.title}
        listItems={ListStep3.items}
        imageUrl={YouGrowWithoutLimitsImg}
        wrapClassName=""
        stepCount="03"
        btnClick={() => btnClick("you-grow-without-limits")}
      />
      <div className="common-bg-light mt-70 ">
        <div className="body-container">
          <RevealAnimation
            component={"h2"}
            className="title-header title-underline"
          >
            Real Value for IT Leaders
          </RevealAnimation>
        </div>
        <CardDetailsWithIcon
          wrapClassName="-mb-120"
          heading="Director of Engineering"
          title="Lead with confidence"
          icon={DOEImg}
          description="As the Director of Engineering, your primary responsibility is to drive the technical direction of the company and ensure successful execution of engineering projects. With Our Pod Model, you have a dedicated team of skilled engineers in India who collaborate seamlessly with your on-site teams. You’ll receive regular updates and reports from Digiryte, allowing you to lead with confidence and ensure alignment with strategic objectives."
        />
      </div>
      <div className="mt-140">
        <CardDetailsWithIcon
          containerClassName="justify-end"
          heading="CTO"
          title="Innovate with ease"
          icon={CTOImg}
          description="As the CTO, innovation is at the heart of your role. Our Pod Model empowers you to focus on driving innovation and staying ahead of the competition by providing access to a highly talented pool of engineers in India. With Our Pod Model, your product team collaborates closely to deliver cutting-edge solutions that align with your company’s vision and goals."
        />
      </div>
      <div className="mt-70">
        <CardDetailsWithIcon
          heading="VP of Engineering"
          title="Drive efficiency and excellence"
          icon={VPEImg}
          description="As the VP of Engineering, your key objective is to ensure the efficient execution of engineering projects while maintaining high standards of quality and performance. With Our Pod Model, you have direct communication channels with your engineers in India, enabling you to oversee projects effectively and address any challenges proactively. You’ll receive regular updates and insights from Digiryte to drive continuous improvement and excellence in engineering operations."
        />
      </div>
      <div className="mt-70">
        <CardDetailsWithIcon
          containerClassName="justify-end"
          heading="Head of Software Development"
          title="Empower your team"
          icon={HSDImg}
          description="As the Head of Software Development, you play a crucial role in leading and managing the software development efforts of the company. Our Pod Model provides you with a dedicated team of skilled developers in India who work seamlessly alongside your on-site teams. You have the tools and resources to empower your team, streamline workflows, and ensure the timely delivery of high-quality software solutions."
        />
      </div>
      <div className="mt-70">
        <CardDetailsWithIcon
          heading="Product Manager"
          title="Streamline collaboration"
          icon={PMImg}
          description="As the Product Manager, collaboration is key to your success. Our Pod Model enables you to work closely with your engineers in India, leveraging the same methodologies and tools as your on-site teams. You have full visibility into the development process, allowing you to streamline collaboration, prioritise tasks, and drive the successful delivery of products that meet customer needs and expectations."
        />
      </div>
      <div className="mt-70">
        <LetsTalkCTA />
      </div>
      <div className="mt-70 common-bg-light">
        <div className="body-container">
          <RevealAnimation
            component={"h2"}
            className="title-header title-underline"
          >
            Why Our Pod Model Standout?
          </RevealAnimation>
          <div className="table-wrap">
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <p>Hiring Locally</p>
                    </th>
                    <th>
                      <p>Contractors</p>
                    </th>
                    <th className="active">
                      <p>Digiryte's Pod Model</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, index) => (
                    <RevealAnimation component={"tr"} key={index}>
                      <td className="bold">
                        <p>{item.name}</p>
                      </td>
                      <td>
                        <p>{item.hireLocally}</p>
                      </td>
                      <td>
                        <p>{item.contractors}</p>
                      </td>
                      <td className="active">
                        <p>{item.digiRyteModel}</p>
                      </td>
                    </RevealAnimation>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-140"></div>
      <div className="common-bg-light bg-white">
        <div className="body-container">
          <RevealAnimation
            component={"h2"}
            className="title-header title-underline"
          >
            Our Experience Building Agile Pods
          </RevealAnimation>
          <RevealAnimation className="mt-30 info-view-content">
            At Digiryte, our Agile Pod services are meticulously designed to
            enhance your project delivery through a dynamic, scalable, and
            highly efficient team structure. Each Agile Pod functions as a
            custom unit, crafted to address your unique project demands,
            blending the perfect mix of talent, technology, and methodology to
            achieve outstanding outcomes for you. Explore the specialised Pods
            we’ve successfully implemented and currently manage, each uniquely
            positioned to elevate our clients operations.
          </RevealAnimation>
        </div>
        {clientStoryData && clientStoryData.length > 0 && (
          <div className="">
            <Miniview
              miniviewData={clientStoryData}
              // navigateComponent={<LeftGrid />}
            />
          </div>
        )}
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            className="mt-30"
            role="presentation"
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={RightArrow}
                  alt="MORE CLIENT STORIES"
                />
              }
              customClassName="info-view-resize-btn bg-white"
              color="primary"
              variant="outlined"
              routepath="/who-we-work-with"
              label="More Client Stories"
              tip="More Client Stories"
              tooltip="no"
            />
          </div>
        </div>
      </div>
      <div className="mt-30">
        <CtaCardSection
          title={
            <>
              <span className="animationTitle">Your developers</span> are
              exclusively <br /> committed to your business, delivering <br />{" "}
              long-lasting value to your organisation
            </>
          }
          titleClassName="customTitle"
          imageUrl={CTAImg}
          imgClassName="custom-cta-img"
          className="common-o-bg"
        />
      </div>
      <div className="mt-70">
        <div className="body-container">
          <RevealAnimation
            component={"h2"}
            className="title-header title-underline"
          >
            The Next Steps with us
          </RevealAnimation>
        </div>
        <div className="body-container">
          <StepsHoverCard />
        </div>
      </div>
    </div>
  );
}

export default Overview;
