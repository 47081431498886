import React from "react";
import Button from "../Button";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import "./style.scss";
import RevealAnimation from "../RevealAnimation";
import { useContactModal } from "../Modal/contactModalContext";
function LetsTalkCTA() {
  const { setOpenModal } = useContactModal();
  return (
    <div className="lets-talk-wrap body-container common-bg-light">
      <div className="lets-talk-container ">
        <RevealAnimation component={"h2"} className="title-header">
          Are you a IT Leader?
        </RevealAnimation>
        <Button
          btnWrapClassName="w-full"
          icon={
            <img
              className="effect-btn-svgicon"
              src={RightArrowWhite}
              alt="Lets Talk"
            />
          }
          customClassName="info-view-resize-btn"
          color="primary"
          variant="contained"
          label="LET'S TALK"
          tooltip="no"
          onClick={() => setOpenModal(true)}
        />
      </div>
    </div>
  );
}

export default LetsTalkCTA;
